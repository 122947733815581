import React from 'react';
import { Container } from 'react-bootstrap';
import { header_big_image, choose_bg, logo_footer, fb_icon } from '../images';
import partnerItems from '../constant/partner-items'
import chooseItems from '../constant/choose-items'
import MainContent from '../components/views/MainContent';
import Contact from '../components/views/Contact';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation()

  const onScrollTo = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
  }

  const TopImage = () => {
    return (
      <div className='bg-container'>
        <img src={header_big_image} alt='Transforming the way of the Data World' />
        <h2>{'Transforming the way\nof the Data World'}</h2>
      </div>
    )
  }

  const PartnerBar = () => {
    return (
      <div className='partner-container'>
        <div className='partner-imgs'>
          {
            partnerItems.map((item, index) => {
              return (
                <div className='partner-img-container' key={index}>
                  <img src={item?.image} alt='Our Partner' />
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  const WhyChooseBar = () => {
    return (
      <div className='choose-container'>
        <img src={choose_bg} alt='Why choose MILLIONS NAVS' height={window.innerWidth <= 800 ? 451 : 641} width={'100%'} />
        <h3>Why choose MILLIONS NAVS</h3>
        <h4>Work With People Who Can Understand Your Needs</h4>
        <div className='choose-list'>
          {
            chooseItems.map((item, index) => {
              return (
                <div className='choose-item' key={index}>
                  <i className="material-icons-outlined" style={{ color: '#FFB800', fontSize: window.innerWidth <= 800 ? '20px' : '28px', fontWeight: '900' }}>
                    done
                  </i>
                  <span>{item?.title}</span>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  const OurInfo = () => {
    return (
      <div className='info-container'>
        <div className='info-address'>
          <div className='info-name'>
            <span>MILLIONS NAVS</span>
            <img src={logo_footer} alt='Millions Navs Footer Logo' />
          </div>
          <span>Rm. 1, 7F., No. 375, Sec. 1, Datong Rd., East Dist., Tainan City 701 , Taiwan (R.O.C.)</span>
          <span>TEL: +866 988089778</span>
          <span>Email: millions82939270@gmail.com</span>
        </div>
        <div className='info-link'>
          <div className='info-useful'>
            <span>Useful Links</span>
            <span onClick={() => onScrollTo('home')}>Home</span>
            <span onClick={() => onScrollTo('about_us')}>About Us</span>
            <span onClick={() => onScrollTo('service')}>Service</span>
            <span>Terms of Service</span>
          </div>
          <div className='info-social'>
            <span>Social Media</span>
            <IconButton>
              <img src={fb_icon} alt='Fb Link' />
            </IconButton>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Container className='main-content-container' id='home'>
      {/* <h1 style={{ color: 'red' }}>{t('greeting')}</h1> */}
      <TopImage />
      <div className='space' style={{ height: window.innerWidth <= 800 ? '100px' : '210px' }} />
      <PartnerBar />
      <MainContent />
      <WhyChooseBar />
      <Contact />
      <OurInfo />
    </Container>
  );
}
export default Home;
