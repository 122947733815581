import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';

const Default = ({ children }) => (
  <Container fluid>
    <Header />
    <Row>
      <Col className="main-content content-default">
        {children}
      </Col>
      <Footer />
    </Row>
  </Container>
);

export default Default;
