import { address_icon, email_icon, phone_icon } from "../images";

const infoItems = [
  {
    title: '地址',
    content: '臺南市東區裕農里裕農路375號7樓之1',
    icon: address_icon,
  },
  {
    title: '電話',
    content: '+886 988089778',
    icon: phone_icon,
  },
  {
    title: '電子信箱',
    content: 'chenpeteres@gmail.com',
    icon: email_icon,
  },
];
export default infoItems;
