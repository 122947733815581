import { partner_1, partner_2, partner_3, partner_4 } from "../images";

const partnerItems = [
  {
    image: partner_1,
  },
  {
    image: partner_2,
  },
  {
    image: partner_3,
  },
  {
    image: partner_4,
  },
];
export default partnerItems;
