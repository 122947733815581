import React from 'react';

function Footer() {
  return (
    <footer>
      <span>
        © Copyright 2022 MILLIONS NAVS. All Rights Reserved
      </span>
    </footer>
  );
}

export default Footer;
