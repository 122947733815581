import React from 'react';
import { Link } from 'react-router-dom';
import { logo_s } from '../../images';
import mainNavItems from '../../constant/main-nav-items';
// import LanguageSwitcher from './LanguageSwitcher';

function Header() {

  const onScrollTo = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <div className='content-header' id='header-desktop'>
        <div className='header-left'>
          <div>
            <Link to='/'>
              <img src={logo_s} alt='Millions Navs Logo' className='logo' />
            </Link>
          </div>
          <h3>MILLIONS NAVS</h3>
        </div>
        <div className='header-right'>
          {
            mainNavItems.map((item, index) => {
              return (
                <div
                  className='header-item'
                  key={index}
                  onClick={() => item?.scrollTo && onScrollTo(item?.scrollTo)}
                >
                  {
                    item?.icon &&
                    <div className='header-icon'>
                      <img src={item?.icon} alt='Language icon' width={'100%'} />
                    </div>
                  }
                  <span>
                    {item?.title}
                  </span>
                </div>
              )
            })
          }
          {/* <LanguageSwitcher /> */}
        </div>
      </div>
    </>
  );
}

export default Header;
