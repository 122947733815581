import React from 'react';
import { contact_bg } from "../../images";
import infoItems from '../../constant/info-items';
import { TextField } from '@mui/material';

function Contact() {

  return (
    <div className='contact-container' id='contact_us'>
      <img src={contact_bg} className='contact-container-bg' alt='Contact Us Background' />
      <div className='contact-content'>
        <div className='contact-info'>
          {
            infoItems.map((item, index) => {
              return (
                <div className='contact-info-item' key={index}>
                  <div className='contact-info-icon'>
                    <img src={item?.icon} alt='Info icon' />
                  </div>
                  <div>
                    <span className='contact-info-title'>{item?.title}</span>
                    <span className='contact-info-content'>{item?.content}</span>
                  </div>
                </div>
              )
            })
          }
          <div className='contact-map'>
            <iframe
              src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=%E8%87%BA%E5%8D%97%E5%B8%82%E6%9D%B1%E5%8D%80%E8%A3%95%E8%BE%B2%E9%87%8C%E8%A3%95%E8%BE%B2%E8%B7%AF375%E8%99%9F7%E6%A8%93%E4%B9%8B1&language=zh-tw`}
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
        </div>
        <div className='contact-form'>
          <div className='contact-form-info'>
            <div className='contact-textfield' style={{ marginRight: window.innerWidth <= 800 ? '20px' : '80px' }}>
              <span>您的名字</span>
              <TextField
                variant="filled"
                className='custom-textfield'
              />
            </div>
            <div className='contact-textfield'>
              <span>電子信箱</span>
              <TextField
                variant="filled"
                className='custom-textfield'
              />
            </div>
          </div>
          <div className='contact-textfield' style={{ marginTop: '35px' }}>
            <span>詢問問題</span>
            <TextField
              variant="filled"
              className='custom-textfield'
            />
          </div>
          <div className='contact-textfield' style={{ marginTop: '35px' }}>
            <span>問題描述</span>
            <textarea
              variant="filled"
              className='custom-textfield'
              style={{ height: window.innerWidth <= 1440 ? '200px' : '300px' }}
            />
          </div>
          <span className='send-btn'>送出</span>
        </div>
      </div>
    </div>
  );
}
export default Contact;