import { language_icon } from "../images";

const mainNavItems = [
  {
    title: 'Home',
    scrollTo: 'home',
  },
  {
    title: 'About Us',
    scrollTo: 'about_us',
  },
  {
    title: 'Service',
    scrollTo: 'service',
  },
  {
    title: 'Contact Us',
    scrollTo: 'contact_us',
  },
  {
    title: '語言',
    icon: language_icon,
  },
];
export default mainNavItems;
