const chooseItems = [
  {
    title: 'Customized Solutions',
  },
  {
    title: 'Precision and Accuracy',
  },
  {
    title: 'Collaboration and Support',
  },
];
export default chooseItems;
