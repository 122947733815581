import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import translationEN from './locales/en/translation.json';
//import translationZH_TW from './locales/zh_tw/translation.json';

i18n.use(initReactI18next).init({
  //resources: {
  //  en: { translation: translationEN },
  //  zh_tw: { translation: translationZH_TW },
  //},
  //lng: 'zh_tw', // Set the default language here
  //fallbackLng: 'zh_tw', // Fallback language if a translation is missing
  //interpolation: {
  //  escapeValue: false, // React already escapes values by default
  //},
});

export default i18n;
