import React from 'react';
import {
  Divider,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { logo_b, case_1 } from '../../images';
import serviceItems from '../../constant/service-items';
import succesfulCaseItems from '../../constant/succesful-case-items';

function MainContent() {

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
  }));
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        <i className="material-icons-outlined" style={{ color: 'white', fontSize: '28px' }}>
          expand_more
        </i>
      }
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    backgroundColor: '#555555',
    borderRadius: '10px',
    marginTop: '16px',
  }));

  const AboutUs = () => {
    return (
      <div className='about-us-container' id='about_us'>
        <h3>About Us</h3>
        <div className='about-us-content'>
          <div className='about-us-label'>
            <span>
              At our core, we envision a future where GNSS data plays a central role in transforming the way people interact with their surroundings. From optimizing logistics and enhancing navigation systems to supporting agriculture, urban planning, and disaster management, we aim to make GNSS technology accessible and beneficial for all.
            </span>
          </div>
          <div className='about-us-logo'>
            <img src={logo_b} alt='Millions Navs Logo' />
          </div>
        </div>
      </div>
    )
  }
  const OurServices = () => {
    return (
      <div className='service-container' id='service'>
        <h3>Our Services</h3>
        <div className='service-content'>
          {
            serviceItems.map((item, index) => {
              return (
                <div className='service-item-container' key={index}>
                  <img src={item?.image} alt='Our Services' />
                  <div className='service-item-title'>
                    <span>{item?.title}</span>
                  </div>
                  <div className='service-item-more'>
                    <span>More...</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
  const SuccesfulCase = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
      <div className='succesful-case-container'>
        <h3>Succesful Case</h3>
        <div className='succesful-case-content'>
          <div className='succesful-case-left'>
            <img src={case_1} alt='Succesful Case' />
          </div>
          <div className='succesful-case-right'>
            {
              succesfulCaseItems.map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    style={{
                      backgroundColor: 'black',
                      marginBottom: window.innerWidth <= 800 ? '25px' : '45px',
                    }}
                    expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}bh-content`}
                      id={`panel${index}bh-header`}
                      style={{
                        border: '3px solid white',
                        borderRadius: '10px',
                        backgroundColor: 'black'
                      }}
                    >
                      <Typography sx={{
                        height: window.innerWidth <= 1440 ? window.innerWidth <= 800 ? '20px' : '35px' : '60px',
                        flexShrink: 0,
                        fontSize: window.innerWidth <= 1440 ? window.innerWidth <= 800 ? '15px' : '28px' : '36px',
                        fontWeight: '400',
                        color: 'white'
                      }}>
                        {item?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {
                        item?.items?.map((itemDetail, i) => {
                          return (
                            <>
                              <div style={{
                                minHeight: window.innerWidth <= 800 ? '40px' : '60px',
                                display: 'flex',
                                alignItems: 'center',
                                color: 'white',
                              }}>
                                <Typography style={{
                                  marginLeft: '10px',
                                  fontSize: window.innerWidth <= 1440 ? window.innerWidth <= 800 ? '15px' : '28px' : '36px',
                                  fontWeight: '400',
                                }}>
                                  {item?.items[i]}
                                </Typography>
                              </div>
                              {i < item?.items.length - 1 && < Divider color='white' />}
                            </>
                          )
                        })
                      }
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='content-container'>
      <AboutUs />
      <OurServices />
      <SuccesfulCase />
    </div>
  );
}
export default MainContent;
