import { service_1, service_2, service_3 } from "../images";

const serviceItems = [
  {
    image: service_1,
    title: 'GNSS Data Processing',
  },
  {
    image: service_2,
    title: 'Geodesy & Monitor Planing',
  },
  {
    image: service_3,
    title: 'Scientific Data Analyst',
  },
];
export default serviceItems;
