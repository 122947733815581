const succesfulCaseItems = [
  {
    title: '01 GNSS Process',
    items: [
      'Post Processing',
      'RTK & PPP',
      'Ionospheric and Tropospheric Modeling',
    ]
  },
  {
    title: '02 Bridge Monitoring',
    items: [],
  },
  {
    title: '03 Slope Monitoring',
    items: [],
  },
];
export default succesfulCaseItems;
