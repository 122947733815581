import './styles/main.scss';
import { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import routes from './routes';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Suspense>
        <Router path="/">
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
              >
                <route.layout>
                  <route.component />
                </route.layout>
              </Route>
            ))}
          </Switch>
        </Router>
      </Suspense>
    </I18nextProvider>
  );
}

export default App;
