import { Default } from './components/layouts';
import Home from './views/Home';
// import NotFound from './views/NotFound';
// import { SUBJECT } from './casl/define';

const routes = [
  {
    path: '/',
    exact: true,
    layout: Default,
    component: Home,
    // subject: SUBJECT.NORMAL_PAGE,
  },
  // {
  //   exact: true,
  //   layout: Default,
  //   component: NotFound,
  // },
];

export default routes;